<template>
  <div class="header">
    <div class="header__inner">
      <header
        id="headerComponent"
        class="header__container"
        :class="{
          'header__container--active' : burgerState,
        }"
      >
        <div class="header__left">
          <a
            class="header__logo-link"
            @click="scrollTo('.school-page__banner')"
          >
            <img
              class="header__logo"
              src="/v2/header/old/logo.svg"
              alt="logo"
              :width="getLogoSizes.width"
              :height="getLogoSizes.height"
            >
          </a>
        </div>

        <ul class="header__list">
          <li class="header__list-item">
            <a
              class="header__anchor"
              @click="scrollTo('.s-about')"
            >
              Обучение
            </a>
          </li>

          <li class="header__list-item">
            <a
              class="header__anchor"
              @click="scrollTo('.school-page__way')"
            >
              Тарифы
            </a>
          </li>

          <li class="header__list-item">
            <a
              class="header__anchor"
              @click="scrollTo('.advantages__container')"
            >
              О школе
            </a>
          </li>

          <li class="header__list-item">
            <a
              class="header__anchor"
              @click="scrollTo('.parents-videos__head')"
            >
              Отзывы
            </a>
          </li>

          <li class="header__list-item">
            <a
              class="header__anchor"
              @click="scrollTo('.index-form')"
            >
              Контакты
            </a>
          </li>
        </ul>

        <div class="header__right">
          <button
            class="header__login-btn m-btn"
            @click="toggleLead2Modal"
          >
            Оставить заявку
          </button>
          <button
            class="header__burger burger m-btn"
            @click="burgerState = !burgerState"
          >
            <div
              class="burger__line burger__line--top"
              :class="{'burger__line--top--active' : burgerState}"
            />

            <div
              class="burger__line burger__line--center"
              :class="{'burger__line--center--active' : burgerState}"
            />

            <div
              class="burger__line burger__line--bottom"
              :class="{'burger__line--bottom--active' : burgerState}"
            />
          </button>

          <div
            class="header__mob-menu m-menu"
            :class="{'m-menu--active' : burgerState}"
          >
            <div class="m-menu__inner">
              <a
                v-if="!forCom"
                :href="`tel:${phone}`"
                class="m-menu__phone"
              >
                {{ phone | prettyPhone }}
              </a>

              <!--
              <a
                v-if="!forCom"
                href="mailto:nstolbova@synergy.ru"
                class="m-menu__email"
              >
                online.school@synergy.ru
              </a>
              -->

              <ul class="m-menu__l-list">
                <li class="m-menu__l-list-item">
                  <a
                    class="m-menu__anchor"
                    @click="scrollTo('.s-about')"
                  >
                    Обучение
                  </a>
                </li>

                <li class="m-menu__l-list-item">
                  <a
                    class="m-menu__anchor"
                    @click="scrollTo('.advantages__container')"
                  >
                    О школе
                  </a>
                </li>

                <li class="m-menu__l-list-item">
                  <a
                    class="m-menu__anchor"
                    @click="scrollTo('.school-page__way')"
                  >
                    Тарифы
                  </a>
                </li>

                <li class="m-menu__l-list-item">
                  <a
                    class="m-menu__anchor"
                    @click="scrollTo('.packages__m-container', null, 350)"
                  >
                    Акции
                  </a>
                </li>

                <li class="m-menu__l-list-item">
                  <a
                    class="m-menu__anchor"
                    @click="scrollTo('.index-form')"
                  >
                    Контакты
                  </a>
                </li>
              </ul>

              <!--
              <div class="m-menu__soc">
                <p class="m-menu__tx">
                  Подписывайтесь в соц.сетях
                </p>

                <ul class="m-menu__s-list">
                  <li class="m-menu__s-list-item">
                    <a
                      class="m-menu__s-link"
                      target="_blank"
                      href="https://dzen.ru/schoolsynergy"
                    >
                      <img
                        class="m-menu__s-icon"
                        src="/v2/header/old/dzen.svg"
                        alt="dzen"
                        width="46px"
                        height="46px"
                      >
                    </a>
                  </li>
                  <li class="m-menu__s-list-item">
                    <a
                      class="m-menu__s-link"
                      target="_blank"
                      href="https://t.me/synergyschool"
                    >
                      <img
                        class="m-menu__s-icon"
                        src="/v2/header/old/telegram.svg"
                        alt="telegram"
                        width="46px"
                        height="46px"
                      >
                    </a>
                  </li>
                  <li class="m-menu__s-list-item">
                    <a
                      class="m-menu__s-link"
                      target="_blank"
                      href="https://vk.com/synergyonlineschool"
                    >
                      <img
                        class="m-menu__s-icon"
                        src="/v2/header/old/vk.svg"
                        alt="vk"
                        width="46px"
                        height="46px"
                      >
                    </a>
                  </li>
                  <li class="m-menu__s-list-item">
                    <a
                      class="m-menu__s-link"
                      target="_blank"
                      href="https://www.youtube.com/@schoolsynergy"
                    >
                      <img
                        class="m-menu__s-icon"
                        src="/v2/header/old/youtube.svg"
                        alt="youtube"
                        width="46px"
                        height="46px"
                      >
                    </a>
                  </li>
                </ul>
              </div>
              -->
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import scrollTo from '~/utils/scrollTo';

import '@/components/svg-icons/login';

export default {
  name: 'AppHeaderSchoolPage',
  props: {
    forCom: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    burgerState: false,
  }),
  computed: {
    ...mapGetters({
      isLaptop: 'isLaptop',
      isTablet: 'isTablet',
    }),
    phone() {
      return process.env.phone;
    },
    getLogoSizes() {
      if (this.isTablet) {
        return {
          width: '111',
          height: '26.72',
        };
      }
      if (this.isLaptop) {
        return {
          width: '120',
          height: '28.88',
        };
      }
      return {
        width: '162',
        height: '39',
      };
    },
  },
  methods: {
    ...mapActions('modals', {
      toggleLead2Modal: 'toggleLead2',
    }),

    scrollTo(elem, pagePath, SCROLL_TOP_OFFSET) {
      this.burgerState = false;
      return scrollTo.call(this, elem, pagePath, SCROLL_TOP_OFFSET);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &__inner {
    position: fixed;
    z-index: 50;
    top: 10px;

    padding: 0 16px;

    width: 100%;

    @include media-down(tablet) {
      padding: 0 8px;
      top: 8px;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    margin: 0 auto;
    padding: 8px 40px;

    max-width: 1320px;
    height: 80px;

    border-radius: 40px;

    background: $white-color;

    box-shadow: 0 0 0 0 $black;

    transition: 0.2s ease-out;

    @include media-down(laptop) {
      padding: 8px;
    }

    @include media-down(tablet) {
      height: 48px;

      border: 2px solid transparent;
      border-radius: 24px;
    }

    @media (hover: hover) {
      &:hover {
        box-shadow: 0 0 0 2px $black, 5px 5px 1px 2px $black;
        transition-delay: 0s;
      }
    }

    &--active {
      box-shadow: 0 0 0 2px $black, 5px 5px 1px 2px $black;
      transition-delay: 0s;

      @include media-down(tablet) {
        border: 2px solid $black;
        border-radius: 24px 24px 0 0;

        box-shadow: 2px 4px 0 0 $black;
      }
    }
  }

  &__logo-link {
    display: block;
    width: 162px;
    cursor: pointer;

    @include media-down(laptop) {
      width: 120px;
    }

    @include media-down(tablet) {
      width: 111px;
    }
  }

  &__logo {
    width: 100%;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    max-width: 533px;
    width: 100%;

    @include media-down(laptop) {
      gap: 6px;
    }

    @include media-down(tablet) {
      display: none;
    }
  }

  &__list-item {
    padding-left: 16px;

    a {
      cursor: pointer;
    }

    @include media-down(laptop) {
      padding-left: 10px;
    }

    &:nth-child(1) {
      .header__anchor {
        &::before {
          background-color: #fbbd1e;
        }
      }
    }

    &:nth-child(2) {
      .header__anchor {
        &::before {
          background-color: $blue;
        }
      }
    }

    &:nth-child(3) {
      .header__anchor {
        &::before {
          background-color: $blue;
        }
      }
    }

    &:nth-child(4) {
      .header__anchor {
        &::before {
          background-color: $violet;
        }
      }
    }

    &:nth-child(5) {
      .header__anchor {
        &::before {
          background-color: #61ddbc;
        }
      }
    }
  }

  &__anchor {
    position: relative;

    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    color: $black;

    opacity: 0.7;

    transition: 0.2s ease-out;

    &:hover {
      opacity: 1;

      &::before {
        transform: scale(1.4);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 7px;
      left: -14px;

      display: block;

      width: 6px;
      height: 6px;

      border-radius: 50%;

      transition: 0.2s ease-out;

      @include media-down(laptop) {
        top: 8px;
        left: -11px;
      }
    }
  }

  &__anchor.nuxt-link-exact-active {
    opacity: 1;

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $black;
    }
  }

  &__right {
    display: flex;
    gap: 8px;
  }

  &__login-btn {
    display: flex;
    align-items: center;

    padding: 5px 23px 8px 18px;

    border: 2px solid #fbbd1e;
    border-radius: 50px;

    font-size: 20px;
    font-weight: 700;
    color: #fbbd1e;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      padding: 5px 10px 7px;

      height: 34px;

      font-size: 18px;
      font-weight: 600;
    }

    &:hover {
      background: #fbbd1e;

      color: $black;

      & .header__login-icon {
        stroke: $black;
      }
    }
  }

  &__login-icon {
    width: 20px;
    height: 20px;
    margin-right: 2px;

    fill: transparent;
    stroke: #fbbd1e;

    transition: 0.2s ease-out;
  }

  &__drop-menu {
    margin-right: -10px;

    @include media-down(laptop) {
      margin: 0;

      right: 25px;
    }
  }
}

.burger {
  display: none;

  width: 42px;
  height: 42px;

  border-radius: 50%;

  background: #fbbd1e;

  @include media-down(tablet) {
    width: 34px;
    height: 34px;
    display: block;
  }

  @media (hover: hover) {
    &:hover {
      & .burger__line {
        background: $black;
      }
    }
  }

  &__line {
    width: 18px;
    height: 3px;

    background: $white-color;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      width: 15px;
      height: 2px;
    }

    &--top {
      transform: rotate(-4deg) translate(11px, -3px);

      @include media-down(tablet) {
        transform: rotate(-4deg) translate(9px, -3px);
      }

      &--active {
        transform: rotate(-49deg) translate(5px, 10px);

        @include media-down(tablet) {
          transform: rotate(-49deg) translate(4px, 8px);
        }
      }
    }

    &--center {
      transform: rotate(-4deg) translate(11px, 0);

      @include media-down(tablet) {
        transform: rotate(-4deg) translate(9px, 0);
      }

      &--active {
        transform: rotate(43deg) translate(8px, -8px);

        @include media-down(tablet) {
          transform: rotate(43deg) translate(7px, -6px);
        }
      }
    }

    &--bottom {
      transform: rotate(-4deg) translate(11px, 3px);

      @include media-down(tablet) {
        transform: rotate(-4deg) translate(9px, 3px);
      }

      &--active {
        transform: rotate(-49deg) translate(10px, 6px);

        @include media-down(tablet) {
          transform: rotate(-49deg) translate(8px, 5px);
        }
      }
    }
  }
}

.m-menu {
  display: none;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  padding: 0 8px;

  transition: 0.2s ease-out;
  opacity: 0;
  visibility: hidden;

  @include media-down(tablet) {
    display: block;
  }

  &--active {
    @include media-down(tablet) {
      top: 50px;

      opacity: 1;
      visibility: visible;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 40px;

    border-radius: 0 0 24px 24px;
    border: 2px solid $black;
    border-top: 0;

    background: $white-color;

    box-shadow: 2px 4px 0 0 $black;
  }

  &__phone {
    margin-bottom: 20px;

    font-size: 25px;
    font-weight: 600;
    color: $black;

    text-align: center;
  }

  //&__email {
  //  margin-bottom: 40px;
  //
  //  font-size: 18px;
  //  font-weight: 500;
  //  color: $black;
  //
  //  text-align: center;
  //}

  &__l-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    //margin-bottom: 44px;
  }

  &__l-list-item {
    padding-left: 16px;
    line-height: 42px;

    &:nth-child(1) {
      .m-menu__anchor {
        &::before {
          background: #fbbd1e;
        }
      }
    }

    &:nth-child(2) {
      .m-menu__anchor {
        &::before {
          background: $blue;
        }
      }
    }

    &:nth-child(3) {
      .m-menu__anchor {
        &::before {
          background: $blue;
        }
      }
    }

    &:nth-child(4) {
      .m-menu__anchor {
        &::before {
          background: $violet;
        }
      }
    }

    &:nth-child(5) {
      .m-menu__anchor {
        &::before {
          background: #61ddbc;
        }
      }
    }
  }

  &__anchor {
    position: relative;

    width: 100%;

    background-image: linear-gradient(transparent calc(100% - 2px), $white-color 10px);
    background-repeat: no-repeat;
    background-size: 0 100%;

    font-size: 20px;
    font-weight: 600;
    color: $black;

    transition: 0.2s ease-out;

    &::before {
      content: '';
      position: absolute;
      top: 9px;
      left: -16px;

      display: block;

      width: 6px;
      height: 6px;

      border-radius: 50%;
    }
  }

  &__b-list {
    display: grid;
    gap: 15px;

    margin-bottom: 36px;
  }

  &__b-list-item {
    text-align: center;
  }

  &__link {
    font-size: 20px;
    font-weight: 600;
    color: $black;
  }

  //&__tx {
  //  margin-bottom: 20px;
  //
  //  text-align: center;
  //  font-size: 20px;
  //  font-weight: 600;
  //  color: $black;
  //}
  //
  //&__s-list {
  //  display: flex;
  //  justify-content: center;
  //  gap: 10px;
  //}
  //
  //&__s-link {
  //  position: relative;
  //
  //  display: block;
  //
  //  width: 50px;
  //  height: 50px;
  //
  //  border-radius: 10px;
  //  background-color: $black;
  //  border: 2px solid $black;
  //  box-shadow: 2px 2px 0 0 $black;
  //
  //  transition: 200ms;
  //
  //  &:hover {
  //    box-shadow: none;
  //    transform: translate(2px, 2px);
  //  }
  //}
  //
  //&__s-icon {
  //  width: 100%;
  //  height: 100%;
  //}
}

.drop-menu {
  position: relative;

  @include media-down(tablet) {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 61px;
    right: 58px;

    display: block;

    width: 20px;
    height: 0;

    border-width: 0 2px 0 2px;
    border-color: $black;
    border-style: solid;

    background: $white-color;

    opacity: 0;

    transition: 0.2s ease-out;
  }

  &--active {
    &::before {
      z-index: 1;

      height: 21px;

      opacity: 1;
    }
  }

  &__list {
    position: absolute;
    z-index: -1;
    top: -9px;
    right: -30px;

    display: flex;
    gap: 30px;

    padding: 30px 0;

    width: 353px;
    height: 45px;

    border-radius: 55px;

    border: 2px solid transparent;

    overflow: hidden;

    background: $white-color;

    box-shadow: 5px 5px 0 1px transparent;

    transition: 0.2s ease-out;

    &--active {
      top: 80px;

      padding: 30px 30px;

      height: 78px;

      border-radius: 15px;
      border-color: $black;

      box-shadow: 5px 5px 0 1px $black;
    }
  }

  &__list-item {
    opacity: 0;

    transition: 0.2s ease-out;

    &--active {
      opacity: 1;
    }
  }

  &__anchor {
    position: relative;

    font-family: 'Ysabeau', sans-serif;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    color: $black;

    opacity: 0.7;

    transition: 0.2s ease-out;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
