/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'login': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M7.083 5h6.666v11.667H7.083m3.75-5.834l-2.5 2.5m2.5-2.5l-2.5-2.5m2.5 2.5H4.166" _stroke="#FBBD1E" stroke-width="2"/>'
  }
})
