<template>
  <footer class="footer">
    <div :class="['footer__container', { 'footer__container--com': forCom }]">
      <div class="footer__top">
        <div class="footer__contacts-col contacts">
          <div class="contacts__top">
            <a
              class="contacts__logo-link"
              @click="scrollTo('.school-page__banner')"
            >
              <img
                class="contacts__logo"
                src="/v2/footer/old/logo.svg"
                alt="logo"
              >
            </a>

            <a
              class="contacts__logo-link"
              href="https://sk.ru/"
              target="_blank"
            >
              <img
                class="contacts__logo-skv"
                src="/v2/footer/old/skolkovo.svg"
                alt="skolkovo"
              >
            </a>

            <button
              class="footer__to-top footer__to-top--mob m-btn"
              @click="scrollToTop"
            >
              Вернуться наверх

              <svgicon
                class="footer__icon-arrow"
                name="top-arrow"
                original
              />
            </button>
          </div>

          <a
            v-if="!forCom"
            class="contacts__phone"
            :href="`tel:${phone}`"
          >
            {{ phone | prettyPhone }}
          </a>

          <a
            v-if="!forCom"
            class="contacts__email"
            href="mailto:nstolbova@synergy.ru"
          >
            online.school@synergy.ru
          </a>
        </div>

        <div>
          <div class="footer__pages pages">
            <ul class="pages__m-list">
              <li class="pages__m-list-item">
                <a
                  class="pages__m-link"
                  @click="scrollTo('.s-about')"
                >
                  Обучение
                </a>
              </li>

              <li class="pages__m-list-item">
                <a
                  class="pages__m-link"
                  @click="scrollTo('.advantages__container')"
                >
                  О школе
                </a>
              </li>

              <li class="pages__m-list-item">
                <a
                  class="pages__m-link"
                  @click="scrollTo('.school-page__way')"
                >
                  Тарифы
                </a>
              </li>

              <li class="pages__m-list-item">
                <a
                  class="pages__m-link"
                  @click="scrollTo('.packages__m-container', null, 350)"
                >
                  Акции
                </a>
              </li>

              <li class="pages__m-list-item">
                <a
                  class="pages__m-link"
                  @click="scrollTo('.index-form')"
                >
                  Контакты
                </a>
              </li>
            </ul>
          </div>

          <div
            v-if="!cenyPage"
            class="footer__docs docs docs--desk"
          >
            <ul
              v-if="!forCom"
              class="docs__list"
            >
              <li class="docs__list-item">
                <nuxt-link
                  class="docs__link"
                  target="_blank"
                  to="/rekviziti"
                >
                  Реквизиты
                </nuxt-link>
              </li>

              <li class="docs__list-item">
                <nuxt-link
                  class="docs__link"
                  target="_blank"
                  to="/privacy"
                >
                  Политика конфиденциальности
                </nuxt-link>
              </li>
            </ul>
            <ul
              v-else
              class="docs__list"
            >
              <li class="docs__list-item">
                <button
                  class="m-btn docs__link"
                  @click="$emit('openPrivacy')"
                >
                  Политика конфиденциальности
                </button>
              </li>
            </ul>
          </div>

          <!-- eslint-disable max-len vue/max-len -->
          <a
            v-if="!forCom"
            class="footer__address no-tablet"
            href="https://www.google.com/maps/place/Leningradsky+Ave,+80%D0%BA5,+Moskva,+Russia,+125190/@55.802775,37.5231951,17z/data=!3m1!4b1!4m6!3m5!1s0x46b549c9b692c3f1:0x3dbc0d956b29fe54!8m2!3d55.802775!4d37.52577!16s%2Fg%2F11q99v68bl?entry=ttu"
            target="_blank"
          >
            125315, г. Москва, Ленинградский пр-т, 80к48
          </a>
          <!-- eslint-enable -->
        </div>
      </div>

      <div class="footer__bottom">
        <div class="footer__b-left">
          <div class="footer__docs docs docs--mob">
            <ul
              v-if="!forCom"
              class="docs__list"
            >
              <li class="docs__list-item">
                <nuxt-link
                  class="docs__link"
                  to="/rekviziti"
                  target="_blank"
                >
                  Реквизиты
                </nuxt-link>
              </li>

              <li class="docs__list-item">
                <nuxt-link
                  class="docs__link"
                  target="_blank"
                  to="/privacy"
                >
                  Политика конфиденциальности
                </nuxt-link>
              </li>
            </ul>
            <ul
              v-else
              class="docs__list"
            >
              <li class="docs__list-item">
                <button
                  class="m-btn docs__link"
                  @click="$emit('openPrivacy')"
                >
                  Политика конфиденциальности
                </button>
              </li>
            </ul>
          </div>

          <div
            v-if="!cenyPage"
            class="contacts__msngr"
          >
            <div class="contacts__tx">
              Пишите нам по любым вопросам
            </div>

            <div class="contacts__row-bottom">
              <a
                class="contacts__link"
                href="https://wa.me/79686286327"
                target="_blank"
              >
                <img
                  class="contacts__soc-icon"
                  src="/v2/footer/old/whatsapp.svg"
                  alt="whatsapp"
                >
              </a>

              <a
                class="contacts__link"
                href="https://t.me/schoolsynergy_bot"
                target="_blank"
              >
                <img
                  class="contacts__soc-icon"
                  src="/v2/footer/old/telegram.svg"
                  alt="telegram"
                >
              </a>

              <a
                class="contacts__link"
                href="mailto:nstolbova@synergy.ru"
                target="_blank"
              >
                <img
                  class="contacts__soc-icon"
                  src="/v2/footer/old/email.svg"
                  alt="email"
                >
              </a>
            </div>
          </div>

          <!-- eslint-disable max-len vue/max-len -->
          <a
            v-if="!forCom"
            class="footer__address tablet"
            href="https://www.google.com/maps/place/Leningradsky+Ave,+80%D0%BA5,+Moskva,+Russia,+125190/@55.802775,37.5231951,17z/data=!3m1!4b1!4m6!3m5!1s0x46b549c9b692c3f1:0x3dbc0d956b29fe54!8m2!3d55.802775!4d37.52577!16s%2Fg%2F11q99v68bl?entry=ttu"
            target="_blank"
          >
            125315, г. Москва, Ленинградский пр-т, 80к48
          </a>
          <!-- eslint-enable -->

          <div class="footer__copyrigth">
            {{ forCom ? '© Synergy Advisory Facility Management LLC' : `© Онлайн-школа «Синергия», ${currentYear}` }}
          </div>
        </div>

        <div class="footer__b-rigth">
          <div
            v-if="!cenyPage"
            class="footer__bold-tx"
          >
            Подписывайтесь в соц.сетях
          </div>

          <div
            v-if="!cenyPage"
            class="footer__b-row"
          >
            <img
              v-if="!forCom"
              class="footer__sticker"
              src="/v2/footer/old/sticker.png"
              alt="sticker"
            >

            <a
              class="footer__b-soc"
              href="https://dzen.ru/schoolsynergy"
              target="_blank"
            >
              <img
                class="footer__b-icon"
                src="/v2/footer/old/dzen.svg"
                alt="dzen"
              >
            </a>

            <a
              class="footer__b-soc"
              href="https://t.me/synergyschool"
              target="_blank"
            >
              <img
                class="footer__b-icon"
                src="/v2/footer/old/telegram.svg"
                alt="telegram"
              >
            </a>

            <a
              class="footer__b-soc"
              href="https://vk.com/synergyonlineschool"
              target="_blank"
            >
              <img
                class="footer__b-icon"
                src="/v2/footer/old/vk.svg"
                alt="vk"
              >
            </a>

            <a
              class="footer__b-soc"
              href="https://www.youtube.com/@schoolsynergy"
              target="_blank"
            >
              <img
                class="footer__b-icon"
                src="/v2/footer/old/youtube.svg"
                alt="youtube"
              >
            </a>
          </div>

          <button
            class="footer__to-top footer__to-top--desk m-btn"
            @click="scrollToTop"
          >
            Вернуться наверх

            <svgicon
              class="footer__icon-arrow"
              name="top-arrow"
              original
            />
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import scrollTo from '~/utils/scrollTo';

import '@/components/svg-icons/top-arrow';

export default {
  name: 'AppFooterSchoolPage',
  props: {
    forCom: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    currentYear: new Date().getFullYear(),
  }),
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    phone() {
      return process.env.phone;
    },

    cenyPage() {
      return this.$route.name === 'online-school-ceny';
    },
  },

  methods: {
    ...mapActions('modals', {
      toggleAuthModal: 'toggleAuth',
    }),
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollTo(elem) {
      return scrollTo.call(this, elem);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: $black;
  overflow: hidden;
  position: relative;
  z-index: 5;

  &__container {
    margin: 0 auto;
    padding: 50px 16px 20px;

    max-width: 1352px;
    width: 100%;

    @include media-down(tablet) {
      padding: 20px 16px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;

    margin-bottom: 20px;

    @include media-down(tablet) {
      flex-direction: column;
    }
  }

  &__pages {
    @include media-down(tablet) {
      margin: 0 auto 36px;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;

    @include media-down(tablet) {
      flex-direction: column-reverse;
    }
  }

  &__b-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include media-down(tablet) {
      align-items: center;
    }
  }

  &__address {
    display: block;

    margin-bottom: 20px;
    margin-top: 38px;

    font-size: 20px;
    font-weight: 500;
    color: #a7a7a7;
    text-align: right;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      margin-top: 0;
      margin-bottom: 48px;

      max-width: 327px;

      font-size: 18px;
      color: $white-color;
      text-align: center;
    }

    &:hover {
      color: $white-color;
    }
  }

  &__copyrigth {
    font-size: 18px;
    color: #a7a7a7;

    @include media-down(mobile) {
      text-align: center;
    }
  }

  &__b-rigth {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include media-down(tablet) {
      align-items: center;

      margin-bottom: 48px;
    }
  }

  &__bold-tx {
    margin-bottom: 20px;

    font-size: 20px;
    font-weight: 700;
    color: $white-color;
    text-align: right;
  }

  &__b-row {
    display: flex;
    gap: 10px;

    margin-bottom: 40px;
  }

  &__b-soc {
    position: relative;

    display: block;

    width: 50px;
    height: 50px;

    &:hover {
      & .footer__b-icon {
        transform: translate(2px, 2px);
      }
    }

    &::before {
      content: '';

      position: absolute;
      top: 2px;
      right: -2px;

      display: block;

      width: 50px;
      height: 50px;

      border-radius: 10px;

      background: $white-color;
    }
  }

  &__b-icon {
    position: relative;

    width: 50px;
    height: 50px;

    transition: 0.2s ease-out;
  }

  &__sticker {
    position: absolute;
    bottom: -20px;
    right: 230px;

    pointer-events: none;

    @include media-down(tablet) {
      top: 180px;
      right: -17px;

      width: 90px;

      transform: rotate(33deg);
    }
  }

  &__to-top {
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 18px;
    font-weight: 700;
    color: $white-color;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      font-size: 14px;
    }

    &:hover {
      opacity: 0.6;
    }

    &--desk {
      @include media-down(tablet) {
        display: none;
      }
    }

    &--mob {
      display: none;

      margin-left: auto;

      @include media-down(tablet) {
        display: flex;
      }
    }
  }

  &__icon-arrow {
    width: 16px;
    height: 24px;
  }
}

.contacts {
  display: grid;

  @include media-down(tablet) {
    width: 100%;
  }

  &__top {
    display: flex;
    gap: 36px;

    margin-bottom: 40px;
  }

  &__logo-link {
    width: fit-content;

    cursor: pointer;
    transition: 0.2s ease-out;

    &:hover {
      opacity: 0.6;
    }
  }

  &__logo {
    display: block;

    width: 100%;

    @include media-down(tablet) {
      width: 111px;
    }
  }

  &__logo-skv {
    transition: 0.2s ease-out;

    @include media-down(tablet) {
      width: 35px;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  &__phone {
    margin-bottom: 20px;

    width: fit-content;

    font-size: 35px;
    font-weight: 600;
    color: $white-color;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      justify-self: center;

      font-size: 25px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__email {
    margin-bottom: 40px;

    width: fit-content;

    font-size: 24px;
    font-weight: 500;
    color: $white-color;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      justify-self: center;

      margin-bottom: 30px;

      font-size: 18px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__msngr {
    margin-bottom: 55px;

    @include media-down(tablet) {
      display: none;
    }
  }

  &__tx {
    margin-bottom: 12px;

    font-size: 20px;
    font-weight: 500;
    color: #a7a7a7;
  }

  &__row-bottom {
    display: flex;
    gap: 10px;
  }

  &__link {
    position: relative;

    display: block;

    width: 32px;
    height: 32px;

    &:hover {
      & .contacts__soc-icon {
        transform: translate(2px, 2px);
      }
    }

    &::before {
      content: '';

      position: absolute;
      top: 2px;
      right: -2px;

      display: block;

      width: 32px;
      height: 32px;

      border-radius: 7px;

      background: $white-color;
    }
  }

  &__soc-icon {
    position: relative;

    width: 32px;
    height: 32px;

    transition: 0.2s ease-out;
  }
}

.pages {
  //max-width: 400px;
  width: 100%;

  @include media-down(tablet) {
    flex-direction: column;
  }

  &__m-list {
    display: flex;
    gap: 30px;
    justify-content: flex-end;

    height: fit-content;

    @include media-down(tablet) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      margin-bottom: 44px;
    }
  }

  &__m-list-item {
    padding-left: 16px;

    @include media-down(tablet) {
      line-height: 42px;
    }

    a {
      cursor: pointer;
      white-space: nowrap;
    }

    &:nth-child(1) {
      .pages__m-link {
        &::before {
          background: #fbbd1e;
        }
      }
    }

    &:nth-child(2) {
      .pages__m-link {
        &::before {
          background: $blue;
        }
      }
    }

    &:nth-child(3) {
      .pages__m-link {
        &::before {
          background: $blue;
        }
      }
    }

    &:nth-child(4) {
      .pages__m-link {
        &::before {
          background: $violet;
        }
      }
    }

    &:nth-child(5) {
      .pages__m-link {
        &::before {
          background: #61ddbc;
        }
      }
    }
  }

  &__m-link {
    position: relative;

    width: 100%;

    background-image: linear-gradient(transparent calc(100% - 2px), $white-color 10px);
    background-repeat: no-repeat;
    background-size: 0 100%;

    font-size: 24px;
    font-weight: 500;
    color: $white-color;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      font-size: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 13px;
      left: -16px;

      display: block;

      width: 6px;
      height: 6px;

      border-radius: 50%;

      @include media-down(tablet) {
        top: 9px;
      }
    }

    &:hover {
      background-size: 100% 100%;

      color: #a7a7a7;
    }
  }

  &__s-list {
    display: grid;
    gap: 22px;

    height: fit-content;

    @include media-down(tablet) {
      justify-items: center;
    }
  }

  &__s-link {
    width: 100%;

    background-image: linear-gradient(transparent calc(100% - 2px), $white-color 10px);
    background-repeat: no-repeat;
    background-size: 0 100%;

    font-size: 24px;
    font-weight: 500;
    color: $white-color;

    cursor: pointer;
    transition: 0.2s ease-out;

    @include media-down(tablet) {
      font-size: 20px;
    }

    &:hover {
      background-size: 100% 100%;

      color: #a7a7a7;
    }
  }
}

.docs {
  margin-top: 44px;
  //max-width: 230px;

  @include media-down(tablet) {
    margin-top: 0;
  }

  &--desk {
    @include media-down(tablet) {
      display: none;
    }
  }

  &--mob {
    display: none;

    margin-bottom: 32px;

    @include media-down(tablet) {
      display: block;
    }
  }

  @include media-down(tablet) {
    max-width: none;
  }

  &__list {
    display: flex;
    justify-content: flex-end;
    gap: 15px;

    height: fit-content;

    @include media-down(tablet) {
      flex-direction: column;
      align-items: center;
      justify-items: center;
      gap: 20px;
    }
  }

  &__link {
    font-size: 24px;
    font-weight: 500;
    color: #a7a7a7;

    text-decoration: underline;
    text-underline-position: under;

    transition: 0.2s ease-out;

    @include media-down(tablet) {
      font-size: 18px;
    }

    &:hover {
      color: $white-color;
    }
  }
}

.footer__container--com {
  .footer__top {
    @include media-up(laptop) {
      margin-bottom: 50px;
    }
  }

  .contacts__msngr {
    @include media-up(laptop) {
      position: relative;
      top: -90px;
    }
  }

  .footer__b-rigth {
    @include media-down(tablet) {
      margin-bottom: 60px;

      .footer__b-row {
        margin-bottom: 0;
      }
    }
  }
}
</style>
